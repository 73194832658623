import React from 'react'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className, imageData }) => {

  return (
    <BackgroundImage
      Tag="section"
      className={ className }
      fluid={ imageData }
    >
    </BackgroundImage>
  );
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  min-height: 60vh;
  background-size: cover; 
`

export default StyledBackgroundSection