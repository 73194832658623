import { Link, withPrefix } from "gatsby";
// import BlogRoll from "../components/BlogRoll";
import React from "react";
import BackgroundSection from "../components/BackgroundSection";
import ImageCarouselWithText from "../components/ImageCarouselWithText";

export function IndexPageSharedComponents(props) {
  return <div>
    {/*Ota kommentit pois kun saadaan uusin kuva.*/}
    {(() => {
      if (!props.hide) {
        return (
          <BackgroundSection className={ "is-fullheight-with-navbar" }
                             imageData={ !!props.childImageSharp ? props.childImageSharp.fluid : props.image }/>
        )
      }
    })()}
    {/*  <BackgroundSection className={ "is-fullheight-with-navbar" }
                       imageData={ !!props.childImageSharp ? props.childImageSharp.fluid : props.image }/>*/}


    <section className="section section--gradient">
      <div className="container">
        <div className="section pt-0 ">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="column is-12 has-text-centered pb-5 mb-2 pt-0">
                  <Link className="btn" to="/contact">
                      <h1 style={{
                          color: "#0052A2",
                          fontFamily: "Courgette",
                          marginBottom: "10px",
                          fontSize: '3em',
                      }}>Yhteystiedot</h1>
                  </Link>
                </div>
                {/*<Features gridItems={ props.gridItems }/>*/}
                <ImageCarouselWithText gridItems={ props.gridItems }/>
                <div className="columns">
                  <div className="column is-12 has-text-centered text-wrap">
                    <Link className="btn" to="https://share-eu1.hsforms.com/1fA0mfGzJT3WeVnHfKh8Xdgf4kv2">
                        <h1
                            style={{
                            color: "#0052A2",
                            fontFamily: "Courgette",
                            marginBottom: "10px",
                            fontSize: '3em',
                        }}>Tilausajot</h1>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>;
}